var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-signup" }, [
    _c("div", { staticClass: "social-signup__caption" }, [
      _vm._v(
        _vm._s(
          _vm.type === "register"
            ? _vm.$t("vue.signupWith")
            : _vm.$t("vue.signinWith")
        )
      ),
    ]),
    _c("div", { staticClass: "social-signup__buttons" }, [
      _c(
        "div",
        {
          staticClass: "social-signup__button",
          on: {
            click: function ($event) {
              return _vm.socialSignIn("google")
            },
          },
        },
        [
          _c("img", {
            staticClass: "social-signup__button__icon",
            attrs: {
              src: require("@/assets/images/register-funnel/google.svg"),
              alt: "Google",
            },
          }),
          _c("span", { staticClass: "social-signup__button__label" }, [
            _vm._v("Google"),
          ]),
        ]
      ),
      !_vm.isMobileApp
        ? _c(
            "div",
            {
              staticClass: "social-signup__button",
              on: { click: _vm.linkedinSignIn },
            },
            [
              _c("img", {
                staticClass: "social-signup__button__icon",
                attrs: {
                  src: require("@/assets/images/register-funnel/linkedin.svg"),
                  alt: "LinkedIn",
                },
              }),
              _c("span", { staticClass: "social-signup__button__label" }, [
                _vm._v("LinkedIn"),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }