<template>
  <div class="social-signup">
    <div class="social-signup__caption">{{ type === 'register' ? $t('vue.signupWith') : $t('vue.signinWith') }}</div>

    <div class="social-signup__buttons">
      <div class="social-signup__button" @click="socialSignIn('google')">
        <img src="@/assets/images/register-funnel/google.svg" alt="Google" class="social-signup__button__icon" />
        <span class="social-signup__button__label">Google</span>
      </div>

      <!-- Commented Facebook Start -->
      <!-- <div class="social-signup__button" @click="socialSignIn('facebook')">
        <img src="@/assets/images/register-funnel/facebook.svg" alt="Facebook" class="social-signup__button__icon" />
        <span class="social-signup__button__label">Facebook</span>
      </div> -->
      <!-- Commented Facebook End -->

      <div v-if="!isMobileApp" class="social-signup__button" @click="linkedinSignIn">
        <img src="@/assets/images/register-funnel/linkedin.svg" alt="LinkedIn" class="social-signup__button__icon" />
        <span class="social-signup__button__label">LinkedIn</span>
      </div>
    </div>
  </div>
</template>
<script>
import { commonFunctions } from '@/mixins/commonFunctions'
import { mobile_check } from '@/mixins/mobile_check'
import { Device } from '@capacitor/device'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
export default {
  name: 'FunnelSocialLogin',
  mixins: [commonFunctions, mobile_check],
  props: {
    type: {
      type: String
    },
    IS_LOTTERY_WINNER: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      code: null,
      state: null,
      externalWindow: null,
      isMobile: false,
      isMobileApp: false,
      windowOptions: {
        width: 700,
        height: 599,
        menubar: 0,
        resizable: 'no',
        location: 'yes',
        scrollbars: 'no',
        centered: true
      }
    }
  },
  async created() {
    this.isMobile = this.mobileCheck()
    this.checkIsMobileApp()
    if (!window.opener) {
      /* This function listens for this event when called from popup window when login is successfull */
      window.processCode = async (data) => {
        this.processCode(data)
      }
    }

    const code = this.$route.query.code
    const state = this.$route.query.state
    const error = this.$route.query.error
    if (code && state) {
      const data = {
        code,
        state
      }
      if (this.isMobile) {
        this.processCode(data)
      } else {
        /* Call process code of parent window to further process the code received */
        window.opener.processCode(data)
        window.close()
      }
    }

    if (error && state) {
      window.close()
      this.$vs.loading.close()
    }
  },
  methods: {
    async checkIsMobileApp() {
      const { platform } = await Device.getInfo()

      if (['ios', 'android'].includes(platform)) {
        this.isMobileApp = true
      }
    },
    async processCode(data) {
      const code = data.code
      const state = data.state
      this.code = code
      this.state = state
      if (code && state) {
        try {
          this.$vs.loading()
          const _socialLoginState = localStorage.getItem('social-login-state')
          localStorage.removeItem('social-login-state')
          const socialLoginState = JSON.parse(_socialLoginState)
          const socialLoginUserProfile = this.$functions.httpsCallable('socialLoginUserProfile')
          const userProfileResult = await socialLoginUserProfile({ code, name: socialLoginState.provider })
          const userProfile = userProfileResult.data

          if (socialLoginState && socialLoginState.state === state && socialLoginState.type === 'login') {
            if (userProfile && userProfile.email) {
              await this.signInWithCustomToken(userProfile.email)
              const firebaseCurrentUser = this.$firebase.auth().currentUser
              if (firebaseCurrentUser) {
                if (!firebaseCurrentUser.company) {
                  const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult(true)
                  if (idTokenResult && idTokenResult.claims && idTokenResult.claims.company) {
                    firebaseCurrentUser.company = idTokenResult.claims.company
                  }
                }
                if (!firebaseCurrentUser.company) {
                  this.$router.push({ name: 'page-register', query: { step: 3, lang: this.$i18n.locale } })
                } else {
                  await this.redirectToTheValidRoute(firebaseCurrentUser.company)
                }
              } else {
                await this.setupUserForRegistration(userProfile, socialLoginState.provider, socialLoginState.type)
              }
            }
          }

          if (socialLoginState && socialLoginState.state === state && socialLoginState.type === 'register') {
            if (userProfile && userProfile.email && userProfile.profile) {
              const userRef = await this.$db.collection('users').where('email', '==', userProfile.email).limit(1).get()

              /* Check if user already exists */
              if (userRef.size > 0) {
                await this.signInWithCustomToken(userProfile.email)
                const firebaseCurrentUser = this.$firebase.auth().currentUser
                if (firebaseCurrentUser) {
                  if (!firebaseCurrentUser.company) {
                    const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult(true)
                    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.company) {
                      firebaseCurrentUser.company = idTokenResult.claims.company
                    }
                  }
                  if (!firebaseCurrentUser.company) {
                    this.$router.push({ name: 'page-register', query: { step: 3, lang: this.$i18n.locale } })
                  } else {
                    await this.redirectToTheValidRoute(firebaseCurrentUser.company)
                  }
                }
              } else {
                await this.setupUserForRegistration(userProfile, socialLoginState.provider, socialLoginState.type)
              }
            } else {
              this.showErrorMessage('error.C_113')
            }
          }
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
        }
      }
    },

    async redirectToTheValidRoute(companyId) {
      const companyDoc = await this.$db.collection('company').doc(companyId).get()
      const _company = companyDoc.data()

      if (
        _company &&
        _company.created &&
        (!_company.paymentStatus || _company.paymentStatus !== 'on-trial') &&
        !_company.currentInvoiceId &&
        !_company.subscriptionId &&
        !_company.subscriptionPlanId &&
        !_company.shopifyStoreId &&
        !_company.appsumoUUID &&
        _company.planType !== 'partner' &&
        this.$route.name !== 'dialog-after-successfull-registration'
      ) {
        this.$router.push({ name: 'page-register', query: { step: 3, lang: this.$i18n.locale } })
      } else {
        const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult(true)
        if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
          this.$router.push({ name: 'home' })
        } else {
          this.$router.push({ name: 'incoming-requests' })
        }
      }
    },

    async setupUserForRegistration(userProfile, provider, type) {
      const _user = {
        displayName: `${[userProfile.profile.firstname, userProfile.profile.lastname].join(' ').trim()}`,
        email: userProfile.email
      }

      const createUserViaOAuth = this.$functions.httpsCallable('createUserViaOAuth')
      const userRecord = await createUserViaOAuth(_user)

      if (userRecord && userRecord.data && userRecord.data.uid) {
        await this.signInWithCustomToken(null, userRecord.data.uid)
        const user = this.$firebase.auth().currentUser
        const result = {}
        if (user) {
          user.provider = provider
          user.firstname = user.firstname || userProfile.firstname
          user.lastname = user.lastname || userProfile.lastname
          user.origin = origin || `${window.location.protocol}//${window.location.host}/pages/${type}`
          let displayName = null
          if (user.firstname && user.lastname) {
            displayName = [user.firstname, user.lastname].join(' ')
          }
          user.fullName = user.displayName || _user.displayName || displayName
          result.user = user
        }
        await this.registerUser(result)
      } else {
        this.showErrorMessage('error.C_114')
      }
    },
    async registerUser(result) {
      const user = result.user
      let userRef = null,
        userData = null
      if (user && user.uid) {
        userRef = await this.$db.collection('users').doc(user.uid).get()
        userData = userRef.data()
      }

      if (userRef && userRef.exists && userData && userData.email) {
        if (!userData.company) {
          this.$router.push({ name: 'page-register', query: { step: 3, lang: this.$i18n.locale } })
        } else {
          await this.redirectToTheValidRoute(userData.company)
        }
      } else {
        this.$vs.loading()

        const displayName = user.displayName || user.fullName || user.email
        const names = displayName.split(' ')
        const firstname = user.firstname || names[0] || ''
        names.shift()
        const lastname = user.lastname || names.join(' ') || ''
        const language = this.$languagesSuppported.includes(navigator.language.slice(0, 2)) ? navigator.language.slice(0, 2) : this.$defaultLanguage

        const payload = {
          result,
          userDetails: {
            displayName,
            firstname,
            lastname,
            email: user.email,
            isNewsLetterAccepted: false,
            language,
            hasSetPassword: !user.provider,
            provider: user.provider || null,
            IS_LOTTERY_WINNER: this.IS_LOTTERY_WINNER
          },
          notify: this.$vs.notify,
          closeAnimation: this.$vs.loading.close
        }

        /* Reseller Program Token Id */
        const tokenID = this.getParameterByName('tokenID') || this.getCookie('tokenID')
        if (tokenID) {
          payload.tokenID = tokenID
        }
        /* SXID */
        const sxid = this.getParameterByName('sxid')
        if (sxid) {
          payload.userDetails.sxid = sxid
        }

        /* Used for Auto creation of company if cookies exist */
        const afid = this.getCookie('afid')
        const cpid = this.getCookie('cpid')
        if (afid) {
          payload.afid = afid
          this.deleteCookie('afid')
          if (cpid) {
            payload.cpid = cpid
            this.deleteCookie('cpid')
          }
        }

        const locationSearch = this.getCookie('locationSearch')
        const urlParams = new URLSearchParams(locationSearch)
        const entries = urlParams.entries()
        const sources = []
        for (const entry of entries) {
          const source = {}
          source[entry[0]] = entry[1]
          sources.push(source)
        }
        payload.sources = sources

        const response = await this.$store.dispatch('auth/registerUser', payload)

        if (response && response.step) {
          this.$emit('set-lottery-data')
        }
      }
    },

    getProvider(name) {
      let provider = null
      switch (name) {
        case 'google':
          provider = new this.$firebase.auth.GoogleAuthProvider()
          break
        case 'facebook':
          provider = new this.$firebase.auth.FacebookAuthProvider()
          provider.addScope('public_profile')
          break
      }
      return provider
    },

    checkIfProviderHasEmail(providerName, result) {
      let providerEmail = null
      switch (providerName) {
        case 'google':
          if (result && result.additionalUserInfo && result.additionalUserInfo.profile && result.additionalUserInfo.profile.email) {
            providerEmail = result.additionalUserInfo.profile.email
          }
          break
        case 'facebook':
          if (result && result.additionalUserInfo && result.additionalUserInfo.profile && result.additionalUserInfo.profile.email) {
            providerEmail = result.additionalUserInfo.profile.email
          }
          break
      }
      return providerEmail
    },

    getProviderName(providerId) {
      let providerName = null
      switch (providerId) {
        case 'google.com':
          providerName = 'google'
          break
        case 'facebook.com':
          providerName = 'facebook'
          break
      }
      return providerName
    },

    async signInWithCustomToken(email, uid) {
      await this.$firebase.auth().signOut()
      if (email) {
        const userRef = await this.$db.collection('users').where('email', '==', email).limit(1).get()
        let userData = null

        if (userRef && userRef.size === 1) {
          userData = userRef.docs[0].data()
          userData.uid = userRef.docs[0].id
        }

        if (userData && userData.uid) {
          const getUserCustomToken = this.$functions.httpsCallable('getUserCustomToken')
          const customToken = await getUserCustomToken({ uid: userData.uid })
          if (customToken && customToken.data && customToken.data.status) {
            const token = customToken.data.token
            await this.$firebase.auth().signInWithCustomToken(token)
            await this.$firebase.auth().currentUser.getIdTokenResult(true)
          }
        }
      }
      if (uid) {
        const getUserCustomToken = this.$functions.httpsCallable('getUserCustomToken')
        const customToken = await getUserCustomToken({ uid })
        if (customToken && customToken.data && customToken.data.status) {
          const token = customToken.data.token
          await this.$firebase.auth().signInWithCustomToken(token)
          await this.$firebase.auth().currentUser.getIdTokenResult(true)
        }
      }
    },

    async socialSignIn(providerName) {
      const provider = this.getProvider(providerName)
      await this.$vs.loading()

      try {
        let result = null

        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform) && providerName === 'google') {
          const response = await GoogleAuth.signIn()

          if (response && response.email) {
            const userRef = await this.$db.collection('users').where('email', '==', response.email).limit(1).get()

            let user = response

            if (userRef && userRef.size === 1) {
              user = userRef.docs[0].data()
              user.uid = userRef.docs[0].id
            }

            result = {
              ...response,
              user,
              credential: {
                ...response.authentication,
                providerId: 'google.com'
              },
              additionalUserInfo: {
                providerId: 'google.com',
                isNewUser: true,
                profile: response
              }
            }

            if (user && user.uid) {
              result.additionalUserInfo.isNewUser = false
            }
          }
        } else {
          result = await this.$firebase.auth().signInWithPopup(provider)
        }

        const providerEmail = this.checkIfProviderHasEmail(providerName, result)
        if (providerEmail) {
          const providerId = result.credential ? result.credential.providerId : null
          const user = result.user
          if (user && this.type === 'login') {
            await this.signInWithCustomToken(providerEmail)
            const firebaseCurrentUser = this.$firebase.auth().currentUser
            if (!user.company && firebaseCurrentUser) {
              const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult(true)
              if (idTokenResult && idTokenResult.claims && idTokenResult.claims.company) {
                user.company = idTokenResult.claims.company
              }
            }

            if (firebaseCurrentUser) {
              const providerData = firebaseCurrentUser.providerData.find((x) => x.providerId === providerId)
              if (!providerData) {
                await firebaseCurrentUser.linkWithPopup(provider)
                if (firebaseCurrentUser && firebaseCurrentUser.uid) {
                  await this.$db.collection('users').doc(firebaseCurrentUser.uid).set({ provider: providerName }, { merge: true })
                }
              }

              if (!user.company) {
                this.$router.push({ name: 'page-register', query: { step: 3, lang: this.$i18n.locale } })
              } else {
                await this.redirectToTheValidRoute(user.company)
              }
            } else {
              await this.signInWithCustomToken(null, result.user.uid)
              result.user.hasSetPassword = false
              result.user.provider = providerName
              await this.registerUser(result)
            }
          }
          if (user && this.type === 'register') {
            result.user.hasSetPassword = false
            result.user.provider = providerName
            await this.registerUser(result)
          }
        }
      } catch (error) {
        this.handleSocialSignInError(error, provider)
      }

      setTimeout(() => {
        this.$vs.loading.close()
      }, 200)
    },

    showErrorMessage(error) {
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.error'),
        text: this.$i18n.t(error),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
      this.$vs.loading.close()
    },

    async handleSocialSignInError(error, provider) {
      let providerName = this.getProviderName(provider.providerId)
      if (error.code === 'auth/account-exists-with-different-credential' && error.email) {
        this.$vs.loading()
        await this.signInWithCustomToken(error.email)
        const firebaseCurrentUser = this.$firebase.auth().currentUser
        if (firebaseCurrentUser && firebaseCurrentUser.uid) {
          const providerData = firebaseCurrentUser.providerData.find((x) => x.providerId === provider.providerId)
          if (!providerData) {
            await firebaseCurrentUser.linkWithPopup(provider)
          }

          await this.$db.collection('users').doc(firebaseCurrentUser.uid).set({ provider: providerName }, { merge: true })

          if (!firebaseCurrentUser.company) {
            const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult(true)
            if (idTokenResult && idTokenResult.claims && idTokenResult.claims.company) {
              firebaseCurrentUser.company = idTokenResult.claims.company
            }
          }

          if (!firebaseCurrentUser.company) {
            this.$router.push({ name: 'page-register', query: { step: 3, lang: this.$i18n.locale } })
          } else {
            await this.redirectToTheValidRoute(firebaseCurrentUser.company)
          }
        } else {
          /* Register user */
          const createUserViaOAuth = this.$functions.httpsCallable('createUserViaOAuth')
          const userRecord = await createUserViaOAuth({ displayName: 'TEST', email: error.email })
          if (userRecord && userRecord.data && userRecord.data.uid) {
            await this.signInWithCustomToken(null, userRecord.data.uid)
            const names = userRecord.data.displayName.split(' ')
            const firstname = (names && names[0]) || ''
            names.shift()
            const lastname = (names && names.join(' ')) || ''
            const user = this.$firebase.auth().currentUser
            const result = {}
            if (user) {
              const _provider = user.providerData && user.providerData.find((x) => x.providerId)
              if (_provider && _provider.providerId) {
                providerName = this.getProviderName(_provider.providerId) || providerName
              }
              user.provider = providerName
              user.firstname = firstname
              user.lastname = lastname
              user.language = this.$languagesSuppported.includes(navigator.language.slice(0, 2)) ? navigator.language.slice(0, 2) : this.$defaultLanguage
              user.origin = `${window.location.protocol}//${window.location.host}/pages/login`
              user.isNewsLetterAccepted = false
              user.fullName = user.displayName || userRecord.data.displayName
              result.user = user
            }
            await this.registerUser(result)
          } else {
            this.showErrorMessage('error.C_114')
          }
        }
      } else {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        this.$vs.loading.close()
      }
    },

    optionsToString(options) {
      return Object.keys(options)
        .map((key) => `${key}=${options[key]}`)
        .join(',')
    },

    openInNewWindow(uri, name) {
      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - this.windowOptions.width) / 2 / (systemZoom + dualScreenLeft)
      const top = (height - this.windowOptions.height) / 2 / (systemZoom + dualScreenTop)
      this.windowOptions.top = top
      this.windowOptions.left = left

      this.code = null
      this.state = null

      this.externalWindow = window.open(uri, name, this.optionsToString(this.windowOptions))
      const timer = setInterval(() => {
        if (this.externalWindow && this.externalWindow.closed) {
          clearInterval(timer)
          if (!this.code && !this.state) {
            this.$vs.loading.close()
          }
        }
      }, 1000)
    },

    async linkedinSignIn() {
      await this.$vs.loading()

      const state = Math.random().toString(36).substring(2, 15)
      const provider = 'linkedin'
      const socialLoginState = {
        state,
        provider,
        type: this.type
      }

      localStorage.setItem('social-login-state', JSON.stringify(socialLoginState))

      const socialLoginAuthorizeUrl = this.$functions.httpsCallable('socialLoginAuthorizeUrl')
      const result = await socialLoginAuthorizeUrl({ name: 'linkedin', state })

      if (!result || !result.data) {
        await this.$vs.loading.close()
        /* eslint-disable no-console */
        console.log('Could not authorize')
        return
      }

      if (this.isMobile) {
        location.href = result.data
      } else {
        this.openInNewWindow(result.data, 'linkedin-window')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.social-signup {
  padding-top: 45px;

  @media (max-width: 991px) {
    padding-top: 20px;
  }

  &__caption {
    color: rgb(83, 83, 91);
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.1px;
    text-align: center;

    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3 - 18px * 2 / 3);
    height: 60px;
    color: rgb(38, 38, 41);
    font-family: 'Larsseit-Bold';
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.0888889px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(217, 219, 221);
    box-shadow: 0px 2px 1px rgba(52, 53, 110, 0.0800422);
    border-radius: 8px;
    flex-grow: 1;
    margin: 0px 10px 0px 10px;

    @media (max-width: 991px) {
      flex-direction: column;
      width: calc(100% / 3 - 16px * 2 / 3);
      height: 96px;
      font-size: 14px;
    }

    &:hover {
      border-color: rgb(163, 205, 232);
      box-shadow: 0px 2px 1px rgba(66, 181, 255, 0.263221);
      cursor: pointer;
    }

    &__icon {
      margin-right: 10px;
      width: 35px;
      height: 35px;

      @media (max-width: 991px) {
        margin-right: 0;
        height: 34px;
      }
    }

    &__label {
      @media (max-width: 991px) {
        margin-top: 10px;
      }
    }
  }
}
</style>
